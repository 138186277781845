const localMessages = {
    'en':{
        'username.rule':'Please input your username!',
        'password.rule':'Please input your password!',
        'verifycode.rule':"Please input your verify code!",
        'username.placeholder':'Enter your username',
        'password.placeholder':'Enter your password',
        'verifycode.placeholder':"Enter Verify Code",
        'login.button':'Login',
        'login.mode':'Other login modes：',
        'verifycode.verifycodehassend':"The verify code has been send to your email.",
    },
    'zh':{
        'username.rule':'请输入用户名!',
        'password.rule':'请输入密码!',
        'verifycode.rule':"请输入验证码!",
        'username.placeholder':'请输入用户名',
        'password.placeholder':'请输入密码',
        'verifycode.placeholder':"请输入验证码",
        'login.button':'登录',
        'login.mode':'其他登录方式：',
        'verifycode.verifycodehassend':"验证码已经发送到您的邮箱，请查收",
    }
}


export function formatMessage(params){
	if(params.id&&params.locale) {
		if(localMessages[params.locale][params.id.toLowerCase()]){
			return localMessages[params.locale][params.id.toLowerCase()];
		}
	}
	return params.id;
}