import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import '@/../../common_config/project.config';
import '@/../../common_config/loadAppIdStyle';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import {serverUrl} from './utils/apiUrl';
import {
    message
} from 'antd';


axios.defaults.baseURL = window.getAttribute('serverUrl');
axios.interceptors.response.use(function (response) {
    if (response.data.status != 200) {
            const error = new Error(response.data.message);
            error.name = response.data.messageCode;
            message.error(response.data.message)
            throw response.data
            // return response.data;
       
    }else{
        return response;
    }
}, function (error) {
    return Promise.reject(error);
});


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
