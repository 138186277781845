import React, { Component } from 'react';
import Favicon from 'react-favicon';
import DocumentTitle from 'react-document-title';
import { Button, Carousel, Input, Icon, Form, Select, Switch, message,Row,Col } from 'antd';
import crypto from 'crypto';
import axios from 'axios';
import { formatMessage } from '../localeMessage.js';
import './index.less';

const Option = Select.Option;
const ssoLogin = window.getAttribute('ssoLogin')

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locale: 'zh',
            loading: false,
            selectList: [],
            isCaptch:false,
            appCarouselPicture:[],
            appLogoLogin:"",
            appLogoTitle:"",
            appTitle:"",
            isVerifyCode:0,
            sendVerifyCode:false,
        }
    }

    componentWillMount(){
        if(this.props.location.pathname.split("/")[2].indexOf(".app")==-1){
            window.location.hash = '/404'
            return
        }
        if(this.props.location.pathname.split("/")[2] && this.props.location.pathname.split("/")[2].indexOf(".app")!=-1){
            window.location.hash = `login?appid=${this.props.location.pathname.split("/")[2].split(".")[0]}`
        }
        if(ssoLogin=='true'){
            if(this.props.location.pathname.split("/")[2].split(".")[0] == 'null'){
                window.location.href = `${window.getAttribute('serverUrl')}/security/common/login/${window.localStorage.getItem("appid")}.app`
            }else{
                window.location.href = `${window.getAttribute('serverUrl')}/security/common/login/${this.props.location.pathname.split("/")[2].split(".")[0]}.app`
            }
        }
    }

    componentDidMount() {
        var self = this
        axios.defaults.headers = {
            'languageSetting': this.state.locale == 'en' ? 'en-US' : 'zh-CN',
        }
        document.onkeydown = function (event) {
            var e = event || window.event; 
            if (e && e.keyCode == 13 && !self.state.loading) {
                self.Click();
            }
        };
        axios.post('/security/getAppCarouselPicture', {
            appid: this.props.location.pathname.split("/")[2]?this.props.location.pathname.split("/")[2].split(".")[0]:null,
        })
        .then(function (response) {
            self.setState({
                appCarouselPicture: response.data.result.appCarouselPicture,
            })
        })
        axios.post('/security/getAppTitle', {
            appid: this.props.location.pathname.split("/")[2]?this.props.location.pathname.split("/")[2].split(".")[0]:null,
        })
        .then(function (response) {
            self.setState({
                appLogoLogin: response.data.result.appLogoLogin,
                appLogoTitle: response.data.result.appLogoTitle,
                appTitle: response.data.result.appTitle,
                isVerifyCode:response.data.result.isVerifyCode,
            })
        })
    }

    sendVerifyCode=(userName,password)=>{
        let self = this
        const hash = crypto.createHash('sha256');
        hash.update(password);
        axios.post('/security/login/sendVerifyCode', {
            username: userName,
            password: hash.digest('hex'),
            appid: this.props.location.pathname.split("/")[2]?this.props.location.pathname.split("/")[2].split(".")[0]:null,
            login_type: "0"
        })
        .then(function (response) {
            console.log('see the reponse',response)
            self.setState({
                loading: false,
                sendVerifyCode:true,
            })
        })
        .catch(function (response) {
            self.props.form.setFieldsValue({apatchCode:''})
            self.setState({
                loading: false,
                sendVerifyCode:false,
            })
           if(response.result && response.result.isShowVerCode == '1'){
            self.setState({
                isCaptch: true
            },()=>{
                if(self.state.isCaptch == true)
                self.createCode(4)
            })
           }else{
            self.setState({ 
                isCaptch: false
            })  
           }
        });
    }

    login=(userName,password,verifyCode)=>{
        console.log("wzstest login1.login");
        let self = this
        const hash = crypto.createHash('sha256');
        hash.update(password);
        axios.post('/security/login', {
            username: userName,
            password: hash.digest('hex'),
            verifyCode:verifyCode,
            appid: this.props.location.pathname.split("/")[2]?this.props.location.pathname.split("/")[2].split(".")[0]:null,
            login_type: "0"
        })
        .then(function (response) {
            console.log('see the reponse',response)
            window.location.href = `${window.getAttribute('Redirect')}/loading?token=${response.data.result.access_token}&language=${self.state.locale}&appid=${self.props.location.search.split("?appid=")[1]}`
        })
        .catch(function (response) {
            self.props.form.setFieldsValue({apatchCode:''})
            self.setState({
                loading: false,
            })
           if(response.result && response.result.isShowVerCode == '1'){
            self.setState({
                isCaptch: true
            },()=>{
                if(self.state.isCaptch == true)
                self.createCode(4)
            })
           }else{
            self.setState({ 
                isCaptch: false
            })  
           }
        });
    }

    Click() {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if(!this.props.location.pathname.split("/")[2].split(".")[0]){
                    message.error("地址参数错误，请检查")
                }else{
                    this.setState({
                        loading: true
                    })
                    window.localStorage.setItem("appid",this.props.location.pathname.split("/")[2].split(".")[0])
                    //if(this.state.isVerifyCode&&this.state.isVerifyCode===1){
                    //   this.sendVerifyCode(values.userName,values.password);
                    //} else {
                       this.login(values.userName,values.password,values.verifyCode); 
                    //}
                }
            }
        });
    }
    
    createCode (length) {
    
        var code = "";
        var codeLength = parseInt(length); //验证码的长度
        var checkCode = document.getElementById("checkCode");
        ////所有候选组成验证码的字符，当然也可以用中文的
        var codeChars = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
        'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z',
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'); 
        //循环组成验证码的字符串
        for (var i = 0; i < codeLength; i++)
        {
            //获取随机验证码下标
            var charNum = Math.floor(Math.random() * 62);
            //组合成指定字符验证码
            code += codeChars[charNum];
        }
        if (checkCode)
        {
            //为验证码区域添加样式名
            checkCode.className = "code";
            //将生成验证码赋值到显示区
            checkCode.innerHTML = code;
        }
      }    
    render() {
        const { getFieldDecorator } = this.props.form;
        let checkRule = (rule, value, callback) => {
           
            if (value !== document.getElementById("checkCode").innerHTML) {
              callback('验证码输入有误');
            } else {
              callback([]);
            }
          } 
        return (
            (ssoLogin!='true')?<div>
                <div id='myCarousel'>
                    <Favicon url={this.state.appLogoTitle.showType == "base64"?`data:image/${this.state.appLogoTitle.type};base64,${this.state.appLogoTitle.base64}`:`${window.location.origin}/${this.state.appLogoTitle.path}`} />
                    <DocumentTitle title={this.state.appTitle}/>
                    <Carousel autoplay>
                        {this.state.appCarouselPicture.map(item=>{
                            if(item.showType == "base64"){
                                return (
                                    <div><img src={`data:image/${item.type};base64,${item.base64}`}></img></div>
                                )
                            }else{
                                return (
                                    <div><img src={`${window.location.origin}${item.path}`}></img></div>
                                )
                            }
                        })}
                    </Carousel>
                    <div className='loginContainer'>
                        <div style={{ textAlign: 'right', padding: 10 }}>
                            <span style={{ cursor: "pointer", color: this.state.locale == 'zh' ? 'rgb(24,144,255)' : 'gray' }} onClick={() => {
                                this.props.form.resetFields()
                                this.setState({
                                    locale: 'zh'
                                })
                            }}>中文</span>
                            <span style={{}}> | </span> 
                            <span style={{ cursor: "pointer", color: this.state.locale == 'en' ? 'rgb(24,144,255)' : 'gray' }} onClick={() => {
                                this.props.form.resetFields()
                                this.setState({
                                    locale: 'en'
                                })
                            }}>English</span>
                        </div>
                        <div className='logoName'>
                            <img src={this.state.appLogoLogin.showType == "base64"?`data:image/${this.state.appLogoLogin.type};base64,${this.state.appLogoLogin.base64}`:`${window.location.origin}/${this.state.appLogoLogin.path}`} style={{ width: "50%" }}></img>
                        </div>
                        <div className='inputContainer'>
                            <Form.Item>
                                {getFieldDecorator('userName', {
                                    rules: [{ required: true, message: formatMessage({ locale: this.state.locale, id: 'username.rule' }) }],
                                })(
                                    <Input
                                        placeholder={formatMessage({ locale: this.state.locale, id: 'username.placeholder' })}
                                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        size={'large'}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item style={{ marginTop: "20px" }}>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: formatMessage({ locale: this.state.locale, id: 'password.rule' }) }],
                                })(
                                    <Input.Password
                                        placeholder={formatMessage({ locale: this.state.locale, id: 'password.placeholder' })}
                                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        size={'large'}
                                    />
                                )}
                            </Form.Item>
                            {this.state.sendVerifyCode == true && 
                                <Form.Item style={{ marginTop: "20px" }}>
                                {getFieldDecorator('verifyCode', {
                                    rules: [{ required: true, message: formatMessage({ locale: this.state.locale, id: 'verifycode.rule' }) }],
                                })(
                                    <Input
                                        placeholder={formatMessage({ locale: this.state.locale, id: 'verifycode.placeholder' })}
                                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        size={'large'}
                                    />
                                )}
                                </Form.Item>

                            }
                           { this.state.isCaptch == true && <Row>
                                <Col span={12}>
                                    <Form.Item  style={{ marginTop: "10px" }}>     
                                                {getFieldDecorator('apatchCode', {
                                                    rules: [
                                                        { required: true, message: '请输入验证码!'},
                                                        { validator:checkRule },
                                                    ]
                                                })(
                                                <Input.Password
                                                    placeholder='请输入验证码'
                                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    size={'large'}
                                                />
                                                )}
                                        
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                </Col>
                                <Col span={10}>
                                    <div id="checkCode" className="code"  onClick={()=>this.createCode(4)}></div>
                                </Col>
                            </Row>}                        
                            <div>
                                <Button
                                    type={'primary'}
                                    style={{ width: '100%', marginTop: "20px", marginRight: "10%" }}
                                    onClick={() => this.Click()}
                                    loading={this.state.loading}
                                    elementname={"confirmForm"}
                                >
                                    {formatMessage({ locale: this.state.locale, id: 'login.button' })}
                                </Button>
                            </div>
                            <div style={{ marginTop: "10%" }} id='myicon'>
                                <span style={{ fontSize: "18px", verticalAlign: "middle" }}>{formatMessage({ locale: this.state.locale, id: 'login.mode' })}</span>
                                <Icon type="global" className="icon" onClick={() => {
                                        window.localStorage.setItem("appid",this.props.location.pathname.split("/")[2].split(".")[0])
                                        window.location.href = `${window.getAttribute('serverUrl')}/security/common/login/${this.props.location.pathname.split("/")[2].split(".")[0]}.app`
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div></div>
        );
    }
}

const WrappedNormalLoginForm = Form.create()(App);
export default WrappedNormalLoginForm;