import React, { Component } from 'react';
import Exception from 'ant-design-pro/lib/Exception';
import { Button, Carousel, Input, Icon, Form, Select, Switch, message } from 'antd';
const Option = Select.Option;

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <div>
                <Exception type="404" actions={<div></div>}/>
            </div>
        );
    }
}

export default App;