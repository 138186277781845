import { isDev, originAndHostname, PORT_CONFIG } from './project.config';
window.document.domain = window.location.hostname;
var STYLE_FILE_PROJECT = ''; //login不需要填写

var STYLE_PATH = "/../".concat(STYLE_FILE_PROJECT, "/");
var hash = top.location.hash;
var hashSearch = hash && hash.split('?')[1];
var appidItem = hashSearch && hashSearch.split('&').filter(function (item) {
  return item.startsWith('appid=');
})[0];
var appid = appidItem && appidItem.split('appid=')[1] || top.window.sessionStorage.getItem("appid");

var getPath = function getPath() {
  return isDev ? "".concat(originAndHostname, ":").concat(PORT_CONFIG.login.port, "/") : STYLE_PATH;
};

var setIdStyleContent = function setIdStyleContent(id, content) {
  if (!document.querySelector("#".concat(id))) {
    var style = document.createElement('style');
    style.id = id;
    style.innerHTML = content;
    document.head.appendChild(style);
  }
};

var ajaxSyncGet = function ajaxSyncGet(_ref) {
  var url = _ref.url,
      onsuccess = _ref.onsuccess,
      onerror = _ref.onerror;
  var xhr; // 考虑兼容性

  if (window.XMLHttpRequest) {
    xhr = new XMLHttpRequest();
  } else if (window.ActiveObject) {
    // 兼容IE6以下版本
    xhr = new ActiveXobject('Microsoft.XMLHTTP');
  }

  xhr.open('get', url, false);

  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4) {
      var status = xhr.status;

      if (status >= 200 && status < 300 || status == 304) {
        onsuccess && onsuccess(xhr.responseText, xhr.responseXML);
      } else {
        onerror && onerror(status);
      }
    }
  };

  xhr.send(null);
};

var setLink = function setLink() {
  if (!document.querySelector('#appidStyle')) {
    var link = document.createElement('link'); // 下列属性设置无效，不清楚为啥
    // link.prefetch = 'true';
    // link.rel = 'prefetch';
    // link.rel = 'preload';
    // link.as = 'style';
    // link.importance = 'high';

    link.rel = 'stylesheet';
    link.type = "text/css";
    link.id = 'appidStyle';
    link.href = "".concat(getPath()).concat(appid, ".css?version=").concat(Math.random());
    document.body.insertBefore(link, document.body.firstChild);
  }
};

if (isDev) {
  // 开发环境，采用link标签去加载
  setLink();
} else {
  // 线上环境，采用ajax请求
  ajaxSyncGet({
    url: "".concat(getPath()).concat(appid, ".css"),
    onsuccess: function onsuccess(text, xml) {
      setIdStyleContent('appidStyle', text);
    },
    onerror: function onerror() {}
  });
}