import { Form, Modal, Input, Button, message, Row, Col } from 'antd'
import React, { Component } from 'react'
import axios from 'axios'
import crypto from 'crypto'

class ResetModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sending: false,
      sendhelp:"",
      second: 0,
    }
  }

  getAppid() {
    const appid = window.location.href.split('?appid=')[1]
    return appid ? appid : null
  }

  sendCode() {
    const self = this
    const { form } = this.props
    const username = form.getFieldValue('username')
    if (!username) {
      message.error('请先输入用户名')
      return
    }
    const appid = this.getAppid()
    if (!appid) {
      message.error('参数错误：未获取到appid')
      return
    }
    this.setState({ sending: true }, () => {
      axios
        .post('/security/password/sendVerifyCode', { appid, username })
        .then((res) => {
          console.info('success', res);
          self.setState({ second: res.data.result || 0, sendhelp: "验证发送至邮箱，请注意查收" });
          const timer = setInterval(() => {
            if (self.state.second <= 0) {
              self.setState({ second: 0, sending: false })
              clearInterval(timer)
            } else {
              const temp = {second: self.state.second - 1};
              self.setState(temp)
            }
          }, 1000)
        })
        .catch((err) => {
          console.error('error', err)
          self.setState({ second: 0, sending: false })
        })
    })
  }

  submit() {
    const {
      form: { validateFields },
      onCancel,
    } = this.props
    const appid = this.getAppid()
    if (!appid) {
      message.error('参数错误：未获取到appid')
      return
    }
    validateFields((err, values) => {
      if (!err) {
        const hash = crypto.createHash('sha256')
        hash.update(values.password)
        axios
          .post('/security/password/reset', { appid, ...values, password: hash.digest('hex') })
          .then((res) => {
            message.success(res.data.message)
            onCancel()
          })
          .catch((err) => {})
      }else{
        console.info("err",err);
        if(err.verifyCode && err.verifyCode.errors[0]){
          this.setState({sendhelp:err.verifyCode.errors[0].message})
        }
      }
    })
  }

  checkPassword(rule, value, callback){
    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,10}$/;
    if(!reg.test(value)){
      callback("密码不符合规范,请重新输入!规范为:最少8个最多10个字符，至少1个大写字母，1个小写字母，1个数字和1个特殊字符");
    }else{
      callback()
    }
  }

  render() {
    const { onCancel, form: { getFieldDecorator } } = this.props

    const itemLayout = { labelCol: { span: 4 }, wrapperCol: { span: 20 } }

    return (
      <Modal maskClosable={false} width={400} bodyStyle={{ padding: 10 }} title="重置密码" visible={true} onCancel={onCancel} onOk={this.submit.bind(this)} okText="提交" cancelText="关闭">
        <Form layout="horizontal">
          <Row>
            <Col span={24}>
              <Form.Item label="用户名" {...itemLayout}>
                {getFieldDecorator('username', {
                  rules: [{ required: true, message: '请输入用户名!' }],
                })(<Input placeholder="请输入用户名" style={{ width: '100%' }} />)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="验证码" help={this.state.sendhelp} {...itemLayout}>
                {getFieldDecorator('verifyCode', {
                  rules: [{ required: true, message: '请输入验证码!' },{len:6,message:"验证码需6位"}],
                })(
                  <Input
                    placeholder="请输入验证码"
                    addonAfter={
                      <Button size="small" type="link" loading={this.state.sending} onClick={() => this.sendCode()}>
                        发送验证码{this.state.second > 0 ? '(' + this.state.second + ')' : ''}
                      </Button>
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="新密码" {...itemLayout}>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: '请输入新密码!' },{validator:this.checkPassword}],
                })(<Input.Password placeholder="请输入新密码" />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(ResetModal)
