
import React, { Component } from 'react';
import { Button } from 'antd';
import {HashRouter, Route, Switch, Redirect} from 'react-router-dom';
import 'ant-design-pro/dist/ant-design-pro.css'
import Login from './login'
import Login1 from './login1'
import Bad from './404'

class App extends Component {
  render() {
    return (
      <div>
        <HashRouter>
          <Switch>
            <Route path={'/login'} component={Login} exact></Route>
            <Route path={'/login/:appid'} component={Login1} exact></Route>
            <Route path={'/404'} component={Bad} exact></Route>
            <Route component={Bad} exact></Route>
            {/* <Redirect from="/" to="/login" /> */}
          </Switch>
        </HashRouter>
      </div>
    );
  }
}

export default App;
